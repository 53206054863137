<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF jE mb-4">
			<div class="dF">
				<div>
					<a-input ref="searchInput" v-model="searchQuery" placeholder="Search survey..." style="width: 300px"
						size="large">
						<a-icon slot="prefix" type="search" />
					</a-input>
				</div>
			</div>
		</div>
		<a-card style="padding: 0" class="table-card">
			<a-table :columns="columns" :data-source="records" :row-key="(record) => record.id" :loading="loading">
				<div slot="entries" slot-scope="record" class="dF fC">
					{{ record.entries || 0 }}
				</div>
				<div slot="lastEntry" slot-scope="record" class="dF fC">
					{{ formatDate(record.lastRegistered) }}
				</div>
				<div slot="createdAt" slot-scope="record" class="dF fC">
					{{ formatDate(record.createdAt) }}
				</div>
				<div slot="actions" slot-scope="record">
					<a-button @click="viewReport(record)">View Report</a-button>
				</div>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import { formatDate } from "bh-mod";
import moment from "moment"

export default {
	data() {
		return {
			loading: true,
			searchQuery: "",
			columns: [
				{
					title: 'Survey Name',
					dataIndex: 'name',
					key: 'name',
					sorter: (a, b) =>
						a.name < b.name
							? -1
							: a.name > b.name
								? 1
								: 0,
				},
				{
					title: 'Entries',
					key: 'entries',
					scopedSlots: { customRender: 'entries' },
					sorter: (a, b) => a.entries - b.entries,
				},
				{
					title: 'Last Entry Added',
					scopedSlots: { customRender: 'lastEntry' },
					key: 'lastRegistered',
					sorter: (a, b) => a.lastRegistered - b.lastRegistered,
					defaultSortOrder: 'descend'
				},
				{
					title: 'Date Created',
					scopedSlots: { customRender: 'createdAt' },
					key: 'createdAt',
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: 'Actions',
					scopedSlots: { customRender: 'actions' },
					key: 'actions'
				},
			],
			surveyList: []
		};
	},
	computed: {
		records() {
			let result = this.surveyList;
			if (this.searchQuery) {
				let query = this.searchQuery.toLowerCase().trim();
				result = result.filter((item) => {
					return (
						item.name.toLowerCase().includes(query)
					);
				});
			}
			return result
		},
	},
	methods: {
		formatDate,
		async getSurveyList() {
			this.loading = true;
			try {
				const { data } = await this.$api.get(`/forms/:instance?type=survey&_limit=-1`);
				this.surveyList = data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching survey list. Please try again.`))
				}
			}
			this.loading = false;
		},

		viewReport(record) {
			this.$store.commit('SET_SURVEY', record)
			this.$router.push(`/survey/${record.id}`)
		}
	},
	created() {
		this.getSurveyList();
	},
};
</script>

<style scoped>
</style>
